// IE10&11 Flexbox fix
@media all and (-ms-high-contrast:none) {

  html {
    display: flex;
    flex-direction: column;
  }

}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

  .app,
  app-dashboard,
  app-root {
    height: 200px;
  }

}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }

}

.sidebar-off-canvas {

  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: calc(100vh - #{$navbar-height});
  }

}

@include media-breakpoint-up(lg) {}

//
// Aside Menu
//
.aside-menu-hidden {

  .aside-menu {
    margin-right: - $aside-menu-width;
  }

}

.aside-menu-fixed {

  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }

  }

  .main, .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {

    .main, .app-footer {
      margin-right: 0;
    }

  }

}

.aside-menu-off-canvas {

  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }

  }

}

//
// Breadcrumb
//
.breadcrumb-fixed {

  .main {
    $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
    padding-top: $breadcrumb-height;
  }

  .breadcrumb {
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    z-index: $zindex-sticky - 2;
  }

  // if sidebar + main + aside
  .main:nth-child(2) {

    .breadcrumb {
      right: $aside-menu-width;
      left: $sidebar-width;
    }

  }

  // if sidebar + main
  .main:first-child {

    .breadcrumb {
      right: $aside-menu-width;
      left: 0;
    }

  }

  // if  main + aside
  .main:last-child {

    .breadcrumb {
      right: 0;
    }

  }

  &.sidebar-minimized {

    .main .breadcrumb {
      left: $sidebar-minimized-width;
    }

  }

  &.sidebar-hidden, &.sidebar-off-canvas {

    .main .breadcrumb {
      left: 0;
    }

  }

  &.aside-menu-hidden, &.aside-menu-off-canvas {

    .main .breadcrumb {
      right: 0;
    }

  }

}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}
.sidebar-nav {
  transition: width $layout-transition-speed;
}
.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {

  .breadcrumb-fixed {

    .main:nth-child(2) .breadcrumb {
      right: auto;
      left: auto;
      width: 100%;
    }

  }

}